

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex';

@each $display in $displays {
  .is-#{$display} {
    display: #{$display} !important; }
  @include mobile {
    .is-#{$display}-mobile {
      display: #{$display} !important; } }
  @include tablet {
    .is-#{$display}-tablet {
      display: #{$display} !important; } }
  @include tablet-only {
    .is-#{$display}-tablet-only {
      display: #{$display} !important; } }
  @include touch {
    .is-#{$display}-touch {
      display: #{$display} !important; } }
  @include desktop {
    .is-#{$display}-desktop {
      display: #{$display} !important; } }
  @include desktop-only {
    .is-#{$display}-desktop-only {
      display: #{$display} !important; } }
  @include widescreen {
    .is-#{$display}-widescreen {
      display: #{$display} !important; } }
  @include widescreen-only {
    .is-#{$display}-widescreen-only {
      display: #{$display} !important; } }
  @include fullhd {
    .is-#{$display}-fullhd {
      display: #{$display} !important; } } }

.is-hidden {
  display: none !important; }

.is-sr-only {
  border: none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.01em !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.01em !important; }

@include mobile {
  .is-hidden-mobile {
    display: none !important; } }

@include tablet {
  .is-hidden-tablet {
    display: none !important; } }

@include tablet-only {
  .is-hidden-tablet-only {
    display: none !important; } }

@include touch {
  .is-hidden-touch {
    display: none !important; } }

@include desktop {
  .is-hidden-desktop {
    display: none !important; } }

@include desktop-only {
  .is-hidden-desktop-only {
    display: none !important; } }

@include widescreen {
  .is-hidden-widescreen {
    display: none !important; } }

@include widescreen-only {
  .is-hidden-widescreen-only {
    display: none !important; } }

@include fullhd {
  .is-hidden-fullhd {
    display: none !important; } }

.is-invisible {
  visibility: hidden !important; }

@include mobile {
  .is-invisible-mobile {
    visibility: hidden !important; } }

@include tablet {
  .is-invisible-tablet {
    visibility: hidden !important; } }

@include tablet-only {
  .is-invisible-tablet-only {
    visibility: hidden !important; } }

@include touch {
  .is-invisible-touch {
    visibility: hidden !important; } }

@include desktop {
  .is-invisible-desktop {
    visibility: hidden !important; } }

@include desktop-only {
  .is-invisible-desktop-only {
    visibility: hidden !important; } }

@include widescreen {
  .is-invisible-widescreen {
    visibility: hidden !important; } }

@include widescreen-only {
  .is-invisible-widescreen-only {
    visibility: hidden !important; } }

@include fullhd {
  .is-invisible-fullhd {
    visibility: hidden !important; } }
