$section-padding: 1.25rem 0.75rem;
$navbar-height: 2.75rem;

@import "lib/bulma/bulma.sass";

.is-size-sliding {
    font-size: .5rem !important; }

@media print {
    .is-page-break {
        page-break-after: always; } }
