%checkbox-radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative;
  input {
    cursor: pointer; }
  &:hover {
    color: $input-hover-color; }
  &[disabled],
  fieldset[disabled] & {
    color: $input-disabled-color;
    cursor: not-allowed; } }

.checkbox {
  @extend %checkbox-radio; }

.radio {
  @extend %checkbox-radio;
  & + .radio {
    @include ltr-property("margin", 0.5em, false); } }
